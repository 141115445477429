import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'Assets/images/search.svg';
import { removeSpecialCharacters } from 'utils/HelperFunctions';
import clsx from 'clsx';
import './style.scss';

const CustomSearchBox = ({ handleSearch, width, ref, placeholder, containerClassName }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputText = (e) => {
        const updatedValue = removeSpecialCharacters(e.target.value);
        setInputValue(updatedValue);
        handleSearch(updatedValue);
    };

    return (
        <div
            style={width ? { width } : {}}
            className={clsx({
                'd-flex flex-row-reverse justify-content-start searchbox-container': true,
                [containerClassName]: containerClassName,
            })}
        >
            <input type='text' placeholder={placeholder} ref={ref} value={inputValue} onChange={handleInputText} />
            <SearchIcon />
        </div>
    );
};

CustomSearchBox.propTypes = {
    handleSearch: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ref: PropTypes.any,
    placeholder: PropTypes.string,
    containerClassName: PropTypes.string,
};

export default CustomSearchBox;
