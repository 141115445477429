//This function takes in 2 values custom name for points for the workspace and
//whether the logged in user is an admin and returns the list of nav items accordingly
import { conditionalArrayElem, isSlack } from '../../../utils/HelperFunctions';
import { HISTORY_FROM, PLATFORM } from 'constants.js';
import { FEEDBACK_360 } from 'components/Dashboard/Content/Feedback/constants';
import { SUGGESTION_BOX } from '../Content/SuggestionBox/constants';
/**
 * If subMenu needs to be added, leave path empty on top for that menu item.
 * Always pass [] for subMenu for top level menu items.
 * @returns {Array<Object>}
 */
const getAdminPaths = ({
    isWebOnly = false,
    isPulseEnabled = true,
    isFeedbackFlowCompleted,
    isSuggestionsFlowCompeleted,
    isLeaderboardEnabled,
    isManager,
}) => [
    {
        path: 'home',
        name: 'Home',
        img: 'home',
        subMenu: [],
    },
    {
        path: 'recognitions/configuration',
        name: 'Recognitions',
        img: 'rewards',
        subMenu: [],
    },
    {
        path: 'celebration',
        name: 'Celebrations',
        img: 'celebrations',
        code: HISTORY_FROM.CELEBRATIONS,
        subMenu: [],
    },
    { path: 'bonus', name: 'Bonus', img: 'bonus', code: HISTORY_FROM.BONUS, subMenu: [] },
    ...conditionalArrayElem(!isWebOnly, [
        {
            path: 'employee-awards',
            name: 'Employee Awards',
            img: 'employeeAwards',
            code: HISTORY_FROM.EMPLOYEE_AWARDS,
            subMenu: [],
        },
    ]),
    {
        name: 'Redemptions',
        img: 'redemptions',
        code: HISTORY_FROM.REDEMPTIONS,
        subMenu: [
            {
                path: 'redemptions/configuration',
                name: `Configuration`,
            },

            {
                path: 'redemptions/catalog',
                name: 'Catalog',
            },
            {
                path: 'redemptions/redeem',
                name: `Redeem`,
            },
        ],
    },
    ...conditionalArrayElem(isPulseEnabled, [
        {
            name: 'Engagement',
            img: 'engagement',
            subMenu: [
                {
                    path: 'eNPS',
                    name: 'eNPS',
                },
                {
                    path: 'pulse',
                    name: 'Pulse',
                    code: HISTORY_FROM.PULSE,
                },
            ],
        },
    ]),
    ...conditionalArrayElem(!isWebOnly, [
        {
            ...(!isSuggestionsFlowCompeleted && { path: 'suggestion-box/setup-suggestion-box' }),
            name: SUGGESTION_BOX,
            img: 'suggestionBox',
            subMenu: isSuggestionsFlowCompeleted
                ? [
                      {
                          path: 'suggestion-box/all-suggestions',
                          name: 'All Suggestions',
                      },
                      {
                          path: 'suggestion-box/configuration',
                          name: 'Configuration',
                      },
                  ]
                : [],
        },
        {
            ...(!isFeedbackFlowCompleted && { path: 'feedback/setup-feedback' }),
            name: FEEDBACK_360,
            img: 'feedback',
            subMenu: isFeedbackFlowCompleted
                ? [
                      {
                          path: 'feedback/my-feedback',
                          name: 'My Feedback',
                      },
                      {
                          path: 'feedback/configuration',
                          name: 'Configuration',
                      },
                  ]
                : [],
        },
    ]),
    {
        path: 'activities',
        name: 'Activities',
        img: 'allactivities',
        code: HISTORY_FROM.ALL_ACTIVITIES,
        subMenu: [],
    },
    {
        name: 'Analytics',
        img: 'analytics',
        code: HISTORY_FROM.ANALYTICS,
        subMenu: [
            ...conditionalArrayElem(isManager, [
                {
                    path: 'analytics/team-insights',
                    name: 'My Team Insights',
                },
            ]),
            {
                path: 'analytics/report',
                name: 'Dashboard',
                code: HISTORY_FROM.ANALYTICS,
            },
            ...conditionalArrayElem(isLeaderboardEnabled, [
                {
                    path: 'analytics/leaderboard',
                    name: 'Leaderboard',
                },
            ]),
        ],
    },
    {
        path: 'users',
        name: 'Organization',
        img: 'organization',
        code: HISTORY_FROM.ORG,
        subMenu: [],
    },
];

const getUserPaths = (
    nameForPoints,
    isFeedbackFlowCompleted,
    isLeaderboardEnabled,
    isSuggestionBoxFlowCompleted,
    isSuggestionBoxAdmin,
    isManager,
    isWebOnly = false
) => [
    {
        path: 'home',
        name: 'Home',
        img: 'home',
        subMenu: [],
    },
    {
        path: 'redemptions/redeem',
        name: `Redeem ${nameForPoints}`,
        img: 'userRedeemPoints',
        subMenu: [],
    },
    ...conditionalArrayElem(isLeaderboardEnabled, [
        {
            path: 'analytics/leaderboard',
            name: 'Leaderboard',
            img: 'userLeaderboard',
            subMenu: [],
        },
    ]),
    {
        path: 'activities',
        name: 'My Activities',
        img: 'allactivities',
        code: HISTORY_FROM.ALL_ACTIVITIES,
        subMenu: [],
    },
    ...(!isWebOnly && isSuggestionBoxFlowCompleted
        ? [
              {
                  path: 'suggestion-box/all-suggestions',
                  name: isSuggestionBoxAdmin ? 'All Suggestions' : 'My Suggestions',
                  img: 'suggestionBox',
                  subMenu: [],
              },
          ]
        : []),
    ...(!isWebOnly && isFeedbackFlowCompleted
        ? [
              {
                  path: 'feedback/my-feedback',
                  name: 'My Feedback',
                  img: 'feedback',
                  subMenu: [],
              },
          ]
        : []),
    ...conditionalArrayElem(isManager, [
        {
            path: 'analytics/team-insights',
            name: 'My Team Insights',
            img: 'analytics',
            subMenu: [],
        },
    ]),
];

export const getNavList = ({
    nameForPoints,
    isAdmin,
    userId,
    suggestionBoxAdmins,
    isPulseEnabled,
    platform,
    isFeedbackFlowCompleted,
    isSuggestionsFlowCompeleted,
    isLeaderboardEnabled,
    isManager,
}) => {
    const isWebOnly = platform === PLATFORM.WEB;
    const isSlackPlatform = isSlack(platform);
    const isSuggestionBoxAdmin = suggestionBoxAdmins?.length > 0 && userId === suggestionBoxAdmins[0]._id;

    const ADMIN_PATHS = getAdminPaths({
        isWebOnly,
        isSlackPlatform,
        isPulseEnabled,
        isFeedbackFlowCompleted,
        isSuggestionsFlowCompeleted,
        isLeaderboardEnabled,
        isManager,
    });
    const USER_PATHS = getUserPaths(
        nameForPoints,
        isFeedbackFlowCompleted,
        isLeaderboardEnabled,
        isSuggestionsFlowCompeleted,
        isSuggestionBoxAdmin,
        isManager,
        isWebOnly
    );

    return isAdmin ? ADMIN_PATHS : USER_PATHS;
};
