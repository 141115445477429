import React, { useState } from 'react';
import { getUsers } from 'Services/apiFunctions';
import { GET_USERS_DATA } from 'Services/apiKeys';
import { useInfiniteQuery } from 'react-query';
import PropTypes from 'prop-types';
import CustomDropdown from '../CustomDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import UserButton from 'components/ReusableComponents/UserButton';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { debounce } from 'lodash';
import './style.scss';

const Index = ({
    title,
    selectedUser,
    buttonWidth = 180,
    dropdownWidth = 200,
    handleUserSelection,
    id,
    placeholder = 'Search',
    showTeam = false,
    optionsSelected = true,
}) => {
    const { SetSnackbar } = useToaster();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [dropdownData, setDropdownData] = useState();

    const { fetchNextPage, hasNextPage, isLoading, isFetching } = useInfiniteQuery(
        [GET_USERS_DATA, { search, team: true }],
        getUsers,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: (data) => {
                setDropdownData(data);
            },
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
            retry: 0,
        }
    );

    const handleSearch = debounce((value) => {
        setSearch(value.trim());
    }, 300);

    return (
        <CustomDropdown
            dropDownID={id}
            title={title}
            selectedName={selectedUser}
            buttonWidth={buttonWidth}
            dropdownWidth={dropdownWidth}
            open={dropdownOpen}
            setOpen={setDropdownOpen}
            optionsSelected={optionsSelected}
        >
            <div className='main-infinite-scroll-container'>
                {search || dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                    <div>
                        <CustomSearchBox
                            width={buttonWidth - 10}
                            handleSearch={handleSearch}
                            placeholder={placeholder}
                            value={search}
                        />
                        <div className='infinite-scroll-container'>
                            {dropdownData?.pages[0]?.data?.users?.length > 0 && (
                                <InfiniteScroll
                                    dataLength={dropdownData?.pages?.length * 9}
                                    next={fetchNextPage}
                                    hasMore={hasNextPage}
                                    height={160}
                                    loader={<CustomLoader size={10} />}
                                >
                                    {dropdownData?.pages?.map((pageData) =>
                                        pageData?.data?.users?.map((user) => (
                                            <div className='dropdown-list' key={user?._id}>
                                                <UserButton
                                                    id={user?._id}
                                                    showImage={true}
                                                    onClick={() => {
                                                        handleUserSelection(user);
                                                        setDropdownOpen(false);
                                                    }}
                                                    imageURL={
                                                        user?.pictureURL || require('Assets/images/defaultUser.png')
                                                    }
                                                    senderName={user?.userName}
                                                    showTeam={showTeam}
                                                    teamSize={user?.team?.length}
                                                    customClassname='dropdown-list-item'
                                                />
                                            </div>
                                        ))
                                    )}
                                </InfiniteScroll>
                            )}
                        </div>
                    </div>
                ) : (
                    <div>{(isLoading || isFetching) && <CustomLoader size={10} />}</div>
                )}
            </div>
        </CustomDropdown>
    );
};

Index.propTypes = {
    title: PropTypes.string,
    selectedUser: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonWidth: PropTypes.number,
    dropdownWidth: PropTypes.number,
    handleUserSelection: PropTypes.func,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    showTeam: PropTypes.bool,
    optionsSelected: PropTypes.bool,
};

export default Index;
