import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-query';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import TitleBar from 'components/ReusableComponents/TitleBar';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import CategoriesItem from './CategoriesItem';
import Table from 'components/ReusableComponents/Table';
import { getFeedbackCategoriesList } from '../Services/apiFunctions';
import { GET_FEEDBACK_CATEGORIES_LIST } from 'Services/apiKeys';
import AddCategoryModal from './AddCategoryModal';
import { FEEDBACK, addCategoriesSubText } from '../constants';
import { handleCategoryEdit, handleValueDelete } from '../componentsUtil';
import RemoveCategoryModal from './RemoveCategory';
import { useToaster } from 'Context/SnackbarContext';
import { debounce } from 'lodash';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const searchPlaceholder = 'Search';
    const [addModal, setAddModal] = useState({ open: false, category: '', index: -1 });
    const [deleteModal, setDeleteModal] = useState({ open: false, index: -1 });
    const [categories, setCategories] = useState([]);

    const {
        data: categoriesData,
        isLoading,
        isFetching,
    } = useQuery([GET_FEEDBACK_CATEGORIES_LIST], getFeedbackCategoriesList, {
        onSuccess: (data) => setCategories(data?.categories || []),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    if (isLoading) {
        return <CustomLoader />;
    }

    const handleSearch = debounce((searchValue) => {
        const results = searchValue
            ? categoriesData?.categories.filter((category) => category.includes(searchValue.toLowerCase()))
            : categoriesData?.categories || [];
        setCategories(results);
    }, 300);

    const tableData = categories.map((value, index) => ({
        id: index + 1,
        row: [
            <CategoriesItem
                key={value}
                index={index}
                value={value}
                handleEdit={(open, index, value) => handleCategoryEdit({ open, index, value, setAddModal })}
                handleDelete={(open, index) => handleValueDelete({ open, index, setDeleteModal })}
            />,
        ],
    }));

    return (
        <div className='mb-4'>
            <TitleBar title='Manage Feedback Categories' back onBack={() => history.push(FEEDBACK.CONFIGURATION)} />

            <div className='category-config-container'>
                <div className='add-category-container'>
                    <div className='header'>
                        <h3 className='header-4 header-category'>{`Categories (${categories.length})`}</h3>
                        <div className='header-right-side'>
                            <CustomSearchBox
                                placeholder={searchPlaceholder}
                                width='218px'
                                handleSearch={handleSearch}
                                containerClassName='dept-header-search'
                            />
                            <button
                                className='ew-btn pb-btn'
                                onClick={() => setAddModal({ open: true, category: '', index: -1 })}
                            >
                                Add new category
                            </button>
                            {addModal.open && (
                                <AddCategoryModal
                                    addModal={addModal}
                                    setAddModal={setAddModal}
                                    categories={categories}
                                />
                            )}
                            {deleteModal.open && (
                                <RemoveCategoryModal
                                    categories={categories}
                                    deleteIndex={deleteModal.index}
                                    setDeleteModal={setDeleteModal}
                                />
                            )}
                        </div>
                    </div>
                    <p className='sub-header-category'>{addCategoriesSubText}</p>
                </div>
                <div className='category-list-container'>
                    <Table
                        data={tableData}
                        noShadow={true}
                        loading={isLoading || isFetching}
                        noSortColumns={[0]}
                        columnWidth={['100%']}
                        emptyStateText='No feedback categories found'
                    />
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: propTypes.object,
};

export default Index;
