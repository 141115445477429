import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { ANALYTICS } from '../../constants';
import { useToaster } from 'Context/SnackbarContext';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import Table from 'components/ReusableComponents/Table';
import EmptyScreen from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as EmptyDepartment } from 'Assets/images/empty-department.svg';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import Tooltip from 'components/Styles/Tooltip';
import AvatarGroupPopup from 'components/ReusableComponents/AvatarGroupPopup';
import { GET_DEPT_ANALYTICS_TABLE_DATA } from 'Services/apiKeys';
import { getTableHeadingTitle, getENPSColumnData, showSnackBarMessage } from 'utils/HelperFunctions';
import { getAnalyticsPageTableData } from 'Services/apiFunctions';
import { HISTORY_FROM, COHORT_TYPE } from 'constants.js';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import ToolTipInfo from '../../ToolTipInfo';
import EWButton from 'components/ReusableComponents/EWButton';

let isEnpsSet = false;
let deptTableHeader = ['NAME', 'TOTAL RECOGNITIONS', 'ENGAGEMENT RATE', 'MOST RECOGNIZED FOR'];
let deptNoSortColumns = [0, 3, 4];
let deptColumnWidth = ['22%', '19%', '15%', '19%', '25%'];
let sortKeys = ['name', 'totalRecognitions', 'redeemed', 'creditBalance', 'mostRecognizedFor'];

const Index = ({ history, selectedDateRange, selectedCohort, isFullView = false }) => {
    const { SetSnackbar } = useToaster();
    const { config } = useSelector(mapStateToProps, shallowEqual);
    const [page, setPage] = useState(1);
    const [showEnpsColumn, setShowEnpsColumn] = useState(false);
    const [deptTableData, setDeptTableData] = useState({});
    const [deptFilter, setDeptFilter] = useState({
        page,
        cohortId: selectedCohort?._id,
        cohortType: COHORT_TYPE[selectedCohort?.type?.toUpperCase()],
        queryType: 'department',
        startDate: selectedDateRange?.startDate,
        endDate: selectedDateRange?.endDate,
        limit: isFullView ? 10 : 5,
        view: isFullView ? 'full' : 'card',
        sortValue: -1,
    });

    useEffect(() => {
        setDeptFilter({
            ...deptFilter,
            cohortId: selectedCohort?._id,
            cohortType: COHORT_TYPE[selectedCohort?.type?.toUpperCase()],
            startDate: selectedDateRange?.startDate,
            endDate: selectedDateRange?.endDate,
        });
    }, [selectedDateRange]);

    if (showEnpsColumn) {
        isEnpsSet = true;
        deptTableHeader = ['NAME', 'TOTAL RECOGNITIONS', 'ENGAGEMENT RATE', 'DEPARTMENT ENPS', 'MOST RECOGNIZED FOR'];
        sortKeys = ['name', 'totalRecognitions', 'engagementRate', 'eNPS', 'mostRecognizedFor'];
        deptColumnWidth = ['22%', '19%', '17%', '19%', '23%'];
        deptNoSortColumns = [0, 4];
    }

    const getLoadingOrEmptyStateDeptComponent = (isFetched, isRefetching) => {
        return isFetched && !isRefetching ? (
            <EmptyScreen
                btnName='Setup Organization'
                action={() => history.push('/dashboard/users?type=Departments')}
                image={<EmptyDepartment />}
            />
        ) : (
            <CustomLoader />
        );
    };

    const formatDeptTable = (apiData = []) => {
        return apiData.map((dept) => ({
            id: dept._id,
            row: [
                <div key={dept._id} className='d-flex ac-dept-name-container'>
                    <span className={'ac-light-font pointer-cursor'}>{dept.name}</span>
                    {dept?.team?.length && (
                        <AvatarGroupPopup
                            assignedMembers={dept.team}
                            history={history}
                            navigateTo={HISTORY_FROM.ANALYTICS}
                            className='avatar-group'
                        />
                    )}
                </div>,
                <EWButton
                    plainTextButton={true}
                    primary={false}
                    buttonStyleClass='ac-dark-font pointer-cursor'
                    key={dept._id}
                >
                    {dept.totalRecognitions}
                    <Tooltip
                        white
                        arrow
                        title={
                            <ToolTipInfo
                                givenRecognitions={dept.givenRecognitions}
                                receivedRecognitions={dept.receivedRecognitions}
                            />
                        }
                    >
                        <InfoIcon className='ac-info-icon' />
                    </Tooltip>
                </EWButton>,
                <span key={dept._id} className='ac-dark-font'>
                    {dept?.engagementRate ? `${dept.engagementRate?.toFixed(2)}%` : '-'}
                </span>,
                ...getENPSColumnData(showEnpsColumn || isEnpsSet, dept?.eNPS),
                <span className={isFullView ? 'ac-medium-font' : 'ac-light-font'} key={'deptRecog' + dept?._id}>
                    <EllipsisText white maxWidth='200px'>
                        {dept?.mostRecognizedFor?.trim() || '-'}
                    </EllipsisText>
                </span>,
            ],
        }));
    };

    const { isLoading, isFetching, isFetched, isRefetching } = useQuery(
        [GET_DEPT_ANALYTICS_TABLE_DATA, deptFilter],
        () => getAnalyticsPageTableData(deptFilter),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setDeptTableData(data);
                setShowEnpsColumn(config?.isENPSFlowCompleted && data?.eNPSExists);
            },
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        }
    );

    if (isLoading) {
        return (
            <div className='ac-table-container'>
                <CustomLoader />
            </div>
        );
    }

    let deptAnalyticsData = {
        data: formatDeptTable(deptTableData?.data),
        total: deptTableData?.total,
        currency: deptTableData?.currency,
    };

    const showEmptyState = deptAnalyticsData?.data?.length === 0;

    const handleDeptTableSearch = debounce((value) => {
        setPage(1);
        setDeptFilter({ ...deptFilter, page: 1, searchText: value });
    }, 300);

    const handleDeptTableSort = (index) => {
        const sortValue = -deptFilter.sortValue;
        setDeptFilter({ ...deptFilter, page, sortKey: sortKeys[index], sortValue });
    };

    let paginationProps = null;

    if (isFullView) {
        paginationProps = {
            currentPage: page,
            totalCount: deptAnalyticsData?.total,
            setCurrentPage: setPage,
            onChange: (page) => setDeptFilter({ ...deptFilter, page }),
        };
    }

    return (
        <div className='ac-table-container'>
            <div className='ac-total-count'>
                <p>{getTableHeadingTitle(deptAnalyticsData?.total, 'Department')}</p>
                {isFullView && (
                    <CustomSearchBox
                        width='35%'
                        handleSearch={handleDeptTableSearch}
                        placeholder='Search by department name'
                    />
                )}
            </div>
            {!showEmptyState ? (
                <>
                    <Table
                        header={deptTableHeader}
                        data={deptAnalyticsData.data}
                        noShadow={true}
                        loading={isFetching}
                        noSortColumns={deptNoSortColumns}
                        handleSort={handleDeptTableSort}
                        className='margin-top-8'
                        columnWidth={deptColumnWidth}
                        {...(isFullView && { paginationProps })}
                    />
                    {deptAnalyticsData?.total > 5 && !isFullView && (
                        <EWButton
                            buttonStyleClass='ac-view-all'
                            primary={false}
                            plainTextButton={true}
                            onClick={() => history.push(ANALYTICS.ANALYTICS_DEPARTMENTS)}
                        >
                            {`View all ${
                                deptAnalyticsData?.total > 1 ? deptAnalyticsData?.total + ' Departments' : 'Departments'
                            }`}{' '}
                            <ArrowForwardIcon />
                        </EWButton>
                    )}
                </>
            ) : (
                getLoadingOrEmptyStateDeptComponent(isFetched, isRefetching)
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    config: state.Workspace.todo,
});

Index.propTypes = {
    history: PropTypes.object,
    selectedDateRange: PropTypes.object,
    selectedCohort: PropTypes.object,
    isFullView: PropTypes.bool,
};

export default Index;
