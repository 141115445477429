import React from 'react';
import PropTypes from 'prop-types';
import FeedbackTable from '../FeedbackTable';
import '../style.scss';

const Index = ({
    history,
    data,
    setData,
    requestFeedbackHandler,
    sendFeebackHandler,
    feedBackEnabled,
    platform,
    readFeedbackModal,
    setReadFeedbackModal,
    currentFeedback,
}) => {
    return (
        <div className='my-feedback-container'>
            <FeedbackTable
                history={history}
                feedbackGiven={true}
                data={data}
                setData={setData}
                feedBackEnabled={feedBackEnabled}
                requestFeedbackHandler={requestFeedbackHandler}
                sendFeebackHandler={sendFeebackHandler}
                platform={platform}
                readFeedbackModal={readFeedbackModal}
                setReadFeedbackModal={setReadFeedbackModal}
                currentFeedback={currentFeedback}
            />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    data: PropTypes.object,
    setData: PropTypes.func,
    requestFeedbackHandler: PropTypes.func,
    sendFeebackHandler: PropTypes.func,
    feedBackEnabled: PropTypes.bool,
    platform: PropTypes.string,
    readFeedbackModal: PropTypes.bool,
    setReadFeedbackModal: PropTypes.func,
    currentFeedback: PropTypes.object,
};

export default Index;
