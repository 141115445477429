import React, { useRef, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { find } from 'lodash';
import { useToaster } from 'Context/SnackbarContext';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { updateUserProfile } from 'redux/user/actions';
import PropTypes from 'prop-types';
import InputField from 'components/ReusableComponents/InputField';
import CustomUserTags from 'components/ReusableComponents/CustomUserTags';
import EWButton from 'components/ReusableComponents/EWButton';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import UserSearchDropdown from 'components/ReusableComponents/UserSearchDropdown';
import CustomImageSelect from 'components/ReusableComponents/CustomImageSelect';
import SubscriptionCancelledModal from 'components/Modals/SubscriptionCancelledModal';
import EnhanceWithAI from 'components/ReusableComponents/EnhanceWithAI';
import { default as Tooltip } from 'components/Styles/Tooltip';
import RecognitionSuccessModal from 'components/Dashboard/Content/Recognitions/RecognitionsHome/RecognitionFeed/RecognitionSuccessModal';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import {
    showSnackBarMessage,
    getChannelOptionsByPlatform,
    getIntegratedChannelName,
    isSlack,
    isSubscriptionCancelled,
    capitalizeFirstLetter,
    makePlural,
} from 'utils/HelperFunctions';
import {
    GET_CONFIG_WORKSPACE,
    GET_CORE_VALUES,
    GET_RECOGNITION_TYPES,
    GET_INTEGRATED_CHANNELS,
    GET_FEED_POSTS,
    GET_PENDING_TASKS,
    GET_GIF_LIST,
} from 'Services/apiKeys';
import {
    getWorkspaceConfig,
    getCoreValues,
    getRecognitonTypes,
    getIntegratedChannelsList,
} from 'Services/apiFunctions';
import { postRecognition, getGifListForRecognition } from '../../Recognitions/Services/apiFunctions';
import { ACCESS_FOR } from '../../Recognitions/Configuration/RecognitionConfiguration/constants';
import { HISTORY_FROM, PLATFORM } from 'constants.js';
import './style.scss';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const {
        nameForPoints,
        isAdmin,
        isManager,
        userId,
        creditBalance,
        platform,
        subscription,
        isCompanyValuesRequired,
        maxSelectedCompanyValues,
    } = useSelector(mapStateToProps, shallowEqual);

    // State variables
    const RECOGNITION_CHAR_LIMIT = 2900;
    const RECOGNITION_VALUES_LIMIT = maxSelectedCompanyValues;
    const RESTRICTED_USER_NOTE = 'This user has been restricted from receiving recognitions by the admin.';
    const RECONITION_VALUE_LIMIT_WARNING = `Only ${makePlural(RECOGNITION_VALUES_LIMIT, 'value')} can be added.`;

    const [values, setValues] = useState([]);
    const [recognitionTypes, setRecognitionTypes] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
    const [recognitionMessage, setRecognitionMessage] = useState('');
    const [isAiEnhanced, setIsAiEnhanced] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [recognitionData, setRecognitionData] = useState(null);
    const [selectedGif, setSelectedGif] = useState('');
    const [images, setImages] = useState([]); // Gif list
    const [allowMediaSelection, setAllowMediaSelection] = useState(false);
    const defaultRecognitionChannel = useRef({ enabled: false, channel: {} });

    const getSelectedChannel = () => {
        if (defaultRecognitionChannel.current?.enabled) {
            return defaultRecognitionChannel.current?.channel;
        } else if (channelList?.length === 1) {
            return channelList[0];
        }
        return '';
    };

    const [selectedChannel, setSelectedChannel] = useState(getSelectedChannel());

    const rewardTypeHandler = () => {
        const type = 'kudos';
        const selectedAddOnRewardType =
            find(recognitionTypes, (recognition) => recognition.name.toLowerCase() === type) || recognitionTypes?.[0];
        return selectedAddOnRewardType;
    };
    const [selectedRecognitionType, setSelectedRecognitionType] = useState(rewardTypeHandler());

    const { mutateAsync: sendRecognitionPost, isLoading: isSendingRecognition } = useMutation(postRecognition);

    // Query hooks
    useQuery([GET_INTEGRATED_CHANNELS], getIntegratedChannelsList, {
        onSuccess: (data) => {
            if (data?.length) {
                setChannelList(getChannelOptionsByPlatform(data, platform));
            }
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    useQuery([GET_CONFIG_WORKSPACE], getWorkspaceConfig, {
        onSuccess: (data) => setRecognitionChannelDetails(data),
    });

    useQuery([GET_CORE_VALUES], getCoreValues, {
        onSuccess: (data) => {
            const tempValues = data.values.map((value) => ({
                ...value,
                label: value.value,
                value: value.value,
                isChecked: false,
            }));
            setValues(tempValues);
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        keepPreviousData: true,
    });

    useQuery([GET_RECOGNITION_TYPES], getRecognitonTypes, {
        onSuccess: (data) => setRecognitionTypesData(data),
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
        keepPreviousData: true,
    });

    const updatedRecognitionType = selectedRecognitionType?.name?.toLowerCase().includes('shoutout')
        ? 'shoutout'
        : 'kudos';
    useQuery([GET_GIF_LIST, updatedRecognitionType], getGifListForRecognition, {
        onSuccess: (data) => setImages(data),
        enabled: allowMediaSelection && !!updatedRecognitionType,
    });

    const setRecognitionTypesData = (data) => {
        const tempValues = data?.recognitionTypes
            ?.filter((recognition) => recognition?.points >= 0)
            .map((recognition) => ({
                ...recognition,
                label: `${capitalizeFirstLetter(recognition.name)} (${recognition.points} ${nameForPoints})`,
            }));
        let filteredValues;
        if (isAdmin) {
            filteredValues = tempValues;
        } else if (isManager) {
            filteredValues = tempValues.filter(
                (recognition) =>
                    recognition.allowRecognitionFor.includes(ACCESS_FOR.MANAGER) ||
                    recognition.allowRecognitionFor.includes(ACCESS_FOR.USER)
            );
        } else {
            filteredValues = tempValues.filter((recognition) =>
                recognition.allowRecognitionFor.includes(ACCESS_FOR.USER)
            );
        }
        setRecognitionTypes(filteredValues);
    };

    const setRecognitionChannelDetails = (data) => {
        updateWorkspaceDetails(dispatch, { companyValue: data?.companyValue });
        defaultRecognitionChannel.current = {
            enabled: data?.defaultRecognitionChannel?.enabled,
            channel: {
                ...(isSlack(platform)
                    ? { channelID: data?.defaultRecognitionChannel?.channel?.id }
                    : {
                          channelId: data?.defaultRecognitionChannel?.channel?.id,
                          groupName: data?.defaultRecognitionChannel?.channel?.groupName,
                          groupId: data?.defaultRecognitionChannel?.channel?.groupId,
                          label: getIntegratedChannelName(data?.defaultRecognitionChannel?.channel, platform, true),
                      }),
                channelName: data?.defaultRecognitionChannel?.channel?.name,
                isPrivate: data?.defaultRecognitionChannel?.channel?.isPrivate,
            },
        };
        setAllowMediaSelection(data?.allowRecognitionMediaSelection ?? true);
        setSelectedChannel(getSelectedChannel());
    };

    // Callback on selecting users
    const handleUserSelection = (user) => {
        if (find(selectedUsers, (selectedUser) => selectedUser?._id === user?._id)) {
            setSelectedUsers(selectedUsers.filter((item) => item?._id !== user?._id));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleValuesSelection = (index) => {
        const temp = [...values];
        if (!temp[index].isChecked && selectedValues.length >= RECOGNITION_VALUES_LIMIT) {
            showSnackBarMessage(SetSnackbar, 'warning', RECONITION_VALUE_LIMIT_WARNING);
        } else {
            temp[index].isChecked = !temp[index].isChecked;
            const selectedValuesData = values.reduce((acc, value) => {
                if (value.isChecked) {
                    acc.push(value);
                }
                return acc;
            }, []);
            setSelectedValues(selectedValuesData);
            setValues(temp);
        }
    };

    const isOptionDisabledHandler = (user) => {
        return user.permissions?.recognition?.restricted;
    };

    const userNameHandler = (user) => {
        const isRestricted = user.permissions?.recognition?.restricted;
        return (
            <>
                {user.userName} {isRestricted && <span style={{ fontStyle: 'italic' }}>{` (Restricted) `}</span>}
                {isRestricted && (
                    <Tooltip arrow title={RESTRICTED_USER_NOTE}>
                        <InfoIcon className='info-icon' />
                    </Tooltip>
                )}
            </>
        );
    };

    const shouldDisableRecognition = () => {
        return (
            !selectedRecognitionType ||
            selectedUsers.length < 1 ||
            (recognitionMessage && recognitionMessage?.length > RECOGNITION_CHAR_LIMIT) ||
            !recognitionMessage ||
            recognitionMessage.trim().length < 10 ||
            (platform !== PLATFORM.WEB && !selectedChannel) ||
            (isCompanyValuesRequired && selectedValues.length === 0)
        );
    };

    const handleSuccess = (recognitionData) => {
        setRecognitionData(recognitionData);
        setSuccessModalOpen(true);
        handleCleanUp();
    };

    const handleCleanUp = () => {
        // Reset all states after a successful recognition
        setRecognitionMessage('');
        setSelectedUsers([]);
        setSelectedValues([]);
        setIsAiEnhanced(false);
        setSelectedRecognitionType('');
        setSelectedChannel(getSelectedChannel());
        setSelectedGif('');
        setValues((prevValues) => prevValues.map((value) => ({ ...value, isChecked: false })));
    };

    const handleSendRecognition = async () => {
        if (find(selectedUsers, (user) => user._id === userId)) {
            showSnackBarMessage(SetSnackbar, 'error', `You can't give a ${selectedRecognitionType?.name} to yourself`);
            return;
        }

        if (recognitionMessage?.length < 10) {
            showSnackBarMessage(SetSnackbar, 'error', 'Recognition message must be at least 10 characters long.');
            return;
        }

        if (selectedUsers.length * (selectedRecognitionType?.points || 0) > creditBalance) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                'You do not have enough points to recognize everyone in the list'
            );
            return;
        }

        try {
            if (isSubscriptionCancelled(subscription)) {
                setSubscriptionCancelled(true);
                return;
            }
            const apiData = {
                rewardId: selectedRecognitionType?._id,
                receivers: selectedUsers.map((user) => user._id),
                message: recognitionMessage,
                isAIEnhanced: isAiEnhanced,
                isAddOn: false,
                ...(selectedValues.length > 0 && { values: selectedValues.map((value) => value.id) }),
                ...(platform === PLATFORM.TEAMS && {
                    displayName: selectedChannel?.label,
                    groupId: selectedChannel?.groupId,
                }),
                channel:
                    platform === PLATFORM.WEB
                        ? 'recognition_feed'
                        : selectedChannel?.channelID ?? selectedChannel?.channelId,
                ...(selectedGif && { gifUrl: selectedGif }),
            };

            const recognitionPostData = await sendRecognitionPost({ apiData });
            updateUserProfile(dispatch, {
                creditBalance: creditBalance - selectedUsers.length * selectedRecognitionType?.points,
            });
            queryClient.invalidateQueries(GET_FEED_POSTS);
            queryClient.invalidateQueries(GET_PENDING_TASKS);
            handleSuccess(recognitionPostData?.data); // Reset state after successful recognition
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    return (
        <div className='container-recognition-form'>
            {selectedUsers.length > 0 && (
                <CustomUserTags users={selectedUsers} onRemove={(users) => setSelectedUsers(users)} />
            )}
            {selectedValues.length > 0 && (
                <p className='crf-values'>
                    {`${makePlural(selectedValues.length, 'Value', true)}`} highlighted:&nbsp;
                    <strong>
                        {selectedValues.map((item, index) => (
                            <span key={item.value}>
                                {index > 0 && <span className='values-separator' />}
                                {item.label}
                            </span>
                        ))}
                    </strong>
                </p>
            )}
            {(selectedUsers.length > 0 || selectedValues.length > 0) && <div className='crf-divider' />}

            <InputField
                inputID='rf-recognition-message'
                height='80px'
                inputClass='crf-recogniton-message'
                value={recognitionMessage}
                placeholder='Write your message here&#10;Eg: Congratulation on completing a difficult campaign'
                handleChange={(_id, value) => setRecognitionMessage(value)}
                textArea
                optional={false}
            />
            {recognitionMessage.trim()?.length < 10 && recognitionMessage !== '' && (
                <span className='crf-text-error'>You must enter at least 10 characters.</span>
            )}
            {recognitionMessage?.length > RECOGNITION_CHAR_LIMIT && (
                <span className='crf-text-error'>{`Maximum character limit ${RECOGNITION_CHAR_LIMIT}`}</span>
            )}
            <EnhanceWithAI
                textMessage={recognitionMessage.trim()}
                onMessageEnhanced={(message) => {
                    setRecognitionMessage(message);
                    setIsAiEnhanced(true);
                }}
                handleRevertOriginalMessage={(message) => {
                    setRecognitionMessage(message);
                    setIsAiEnhanced(false);
                }}
                isAiEnhanced={isAiEnhanced}
            />

            {selectedChannel?.channelName && recognitionMessage?.length > 9 && (
                <div className='crf-channel'>
                    {isSlack(platform) ? 'Slack channel' : 'Team'}:{' '}
                    <strong>&nbsp;{getIntegratedChannelName(selectedChannel, platform)}</strong>
                </div>
            )}

            {selectedGif && (
                <div className='crf-gif-preview'>
                    <img src={selectedGif} alt='GIF preview' className='selected-gif' />
                </div>
            )}

            <div className='crf-divider' />

            <div className='crf-selection'>
                <div className='crf-selection-dropdown'>
                    <CustomFilterDropdown
                        search
                        dropDownID='recognition-type'
                        filterOptions={recognitionTypes}
                        selectedName={selectedRecognitionType?.label || 'Recognition Type'}
                        handleSelection={(_index, value) => {
                            setSelectedRecognitionType(value);
                            setSelectedGif(''); // Reset selected gif on changing recognition type
                        }}
                        singleSelect
                        optionsSelected={!!selectedRecognitionType?.label}
                        buttonStyleClass='crf-width-160'
                        dropdownWidth={'356px'}
                        dropdownGap={-4} // align all the dropdowns in a equal height from its selector buttons.
                    />

                    <UserSearchDropdown
                        dropDownID='search-user'
                        dropdownWidth={240}
                        dropdownHeight={200}
                        selectedUsers={selectedUsers}
                        handleUserSelection={handleUserSelection}
                        placeholder='Select Recipient'
                        customAddSearch={false}
                        isRestrictedOptionsAllowed={true}
                        isOptionDisabledHandler={isOptionDisabledHandler}
                        userNameHandler={userNameHandler}
                    />

                    {values.length > 0 && (
                        <CustomFilterDropdown
                            search
                            dropDownID='company-value'
                            filterOptions={values}
                            selectedName={
                                selectedValues.length > 0 ? `Selected (${selectedValues.length})` : 'Choose Value'
                            }
                            handleSelection={(index) => handleValuesSelection(index)}
                            optionsSelected={!!selectedValues.length}
                            buttonStyleClass='crf-width-160 ml-2'
                            dropdownWidth={'356px'}
                            dropdownGap={-4}
                            optional={!isCompanyValuesRequired}
                        />
                    )}

                    {!defaultRecognitionChannel.current?.enabled && (
                        <CustomFilterDropdown
                            search
                            dropDownID='channel'
                            filterOptions={channelList}
                            selectedName={
                                selectedChannel?.channelName
                                    ? getIntegratedChannelName(selectedChannel, platform)
                                    : 'Channel'
                            }
                            handleSelection={(_index, value) => setSelectedChannel(value)}
                            singleSelect
                            optionsSelected={!!selectedChannel?.channelName}
                            buttonStyleClass={`crf-width-160 ${values.length > 0 ? 'mt-2' : 'ml-2'}`}
                            dropdownWidth={'356px'}
                            dropdownGap={-4}
                        />
                    )}

                    {images.length > 0 && (
                        <CustomImageSelect
                            images={images}
                            buttonStyleClass={`crf-width-70 
                                ${values.length > 0 || !defaultRecognitionChannel.current?.enabled ? 'mt-2' : 'ml-2'}`}
                            dropdownStyleClass='crf-gif-dropdown'
                            dropdownWidth={'360px'}
                            dropdownGap={-4}
                            dropdownHeight={'374px'}
                            setSelectedImage={setSelectedGif}
                            selectedImage={selectedGif}
                            disabled={!selectedRecognitionType}
                        />
                    )}
                </div>
                <EWButton
                    buttonStyleClass='ew-btn-slim crf-give-recognition'
                    buttonText='Recognize'
                    onClick={handleSendRecognition}
                    loading={isSendingRecognition}
                    disabled={shouldDisableRecognition()}
                />
            </div>

            <SubscriptionCancelledModal
                open={subscriptionCancelled}
                onClose={() => setSubscriptionCancelled(false)}
                history={history}
                currentRoute={HISTORY_FROM.RECOGNITIONS}
            />

            {successModalOpen && (
                <RecognitionSuccessModal
                    open={successModalOpen}
                    onClose={() => setSuccessModalOpen(false)}
                    recognitionPostData={recognitionData}
                    platform={platform}
                />
            )}
        </div>
    );
};

const mapStateToProps = ({ Workspace, User, Payments }) => ({
    nameForPoints: Workspace.nameForPoints,
    userId: User._id,
    isAdmin: User.isAdmin,
    isManager: User.isManager,
    creditBalance: User.creditBalance || 0,
    platform: Workspace.platform,
    subscription: Payments?.subscription,
    isCompanyValuesRequired: Workspace?.companyValue?.isRequired || false,
    maxSelectedCompanyValues: Workspace?.companyValue?.maxSelectedValues || 3,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
