import { ROUTES, PLATFORM } from 'constants.js';
import { conditionalArrayElem } from 'utils/HelperFunctions';
import { ReactComponent as PulseIcon } from 'Assets/images/noun-discussion.svg';
import { ReactComponent as FeedbackIcon } from 'Assets/images/feedback-icon.svg';
import { ReactComponent as EmployeeAwardsIcon } from 'Assets/images/employeeAwardSidenavIcon.svg';
import { ReactComponent as SuggestionBoxIcon } from 'Assets/images/suggestion-box-icon-otheractions.svg';
import { ReactComponent as BonusIcon } from 'Assets/images/bonus.svg';
import { FEEDBACK } from 'components/Dashboard/Content/Feedback/constants';
import { SUGGESTION } from 'components/Dashboard/Content/SuggestionBox/constants';

export const getQuickActions = ({
    platform,
    isAdmin,
    isSuggestionsFlowCompleted,
    isFeedbackFlowCompleted,
    suggestionBoxEnabled,
    feedbackEnabled,
}) => {
    const isWebOnly = platform === PLATFORM.WEB.toLowerCase();
    const getFeedbackRoute = () =>
        isAdmin && !isFeedbackFlowCompleted ? FEEDBACK.SETUP_FEEDBACK : FEEDBACK.MY_FEEDBACK;

    const getSuggestionBoxRoute = () =>
        isAdmin && !isSuggestionsFlowCompleted ? SUGGESTION.SETUP_SUGGESTION_BOX : SUGGESTION.ALL_SUGGESTION;

    return [
        ...conditionalArrayElem(!isWebOnly && (isAdmin || feedbackEnabled), [
            {
                label: 'Send/Request Feedback',
                route: getFeedbackRoute(),
                Icon: FeedbackIcon,
            },
        ]),
        ...conditionalArrayElem(!isWebOnly && (isAdmin || suggestionBoxEnabled), [
            {
                label: 'Suggestion Box',
                route: getSuggestionBoxRoute(),
                Icon: SuggestionBoxIcon,
            },
        ]),
        // Admin-only actions
        ...conditionalArrayElem(isAdmin, [
            ...conditionalArrayElem(!isWebOnly, [
                {
                    label: 'Launch Award',
                    route: ROUTES.EMPLOYEE_AWARDS_LAUNCH,
                    Icon: EmployeeAwardsIcon,
                },
                {
                    label: 'Launch Pulse',
                    route: ROUTES.PULSE_HOME,
                    Icon: PulseIcon,
                },
            ]),
            {
                label: 'Send Bonus',
                route: ROUTES.BONUS,
                Icon: BonusIcon,
            },
        ]),
    ];
};
